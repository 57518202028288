<template>
	<div class="p-3">
		<div class="row">
			<div class="col px-5">
				<form @submit.prevent="save">
					<div class="form-group">
						<h5>Version</h5>
						<input class="form-control" disabled v-model="model.Version"></input>
					</div>

					<ul class="nav nav-tabs mt-3">
						<li class="nav-item">
							<button class="nav-link text-small" :class="{active: model.Type === 'standard'}"
									@click.prevent="model.Type = 'standard'">Standard
							</button>
						</li>
						<li class="nav-item">
							<button class="nav-link text-small" :class="{active: model.Type === 'stress'}"
									@click.prevent="model.Type = 'stress'">Stress test
							</button>
						</li>
					</ul>

					<div v-if="config" :is="formType" v-model="model" :valid-paylines="config.valid_paylines"></div>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<h5>Bet per line</h5>
								<multiplier :min="1" class="w-auto" v-model="model.Bet"></multiplier>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<h5>Save log</h5>
								<div class="form-check">
									<input class="form-check-input mr-2" id="save_logs" type="checkbox"
										   v-model="model.SaveLogs"
										   value="0">
									<label for="save_logs" class="form-check-label text-small text-light">You need csv
										logs of
										this test?</label>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<h5>Spin type</h5>
								<div>
									<select class="form-control" v-model="model.SpinType">
											<option v-for="(item, index) in SpinTypes" :value="item.id">{{ item.name }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<h5>Notes</h5>
								<textarea v-model="model.Notes" class="form-control"></textarea>
							</div>
						</div>
					</div>

					<button type="submit" class="btn btn-primary px-5 py-2" :disabled="saving || totalSpins === 0">
						<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
							  v-if="saving"></span>
						RUN {{model.Spins.length * model.Paylines.length}} TESTS
						<div class="text-small text-uppercase">{{totalSpins | numeral('0,0')}} spins</div>
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import Multiplier from "@/components/slot-config-editor/prize/multiplier";
	import api from '@/api'
	import Index from "@/components/slot-simulator/index";

	import StandardReportForm from '@/components/reports/types/standard-report-form'
	import StressReportForm from '@/components/reports/types/stress-report-form'

	export default {
		components: {Index, Multiplier, StandardReportForm, StressReportForm},
		data() {
			return {
				SpinTypes: [],
				config: null,
				model: {
					Bet: 1,
					Paylines: [],
					Spins: [],
					Type: 'standard',
					ConfigUuid: this.$route.params.cuuid,
					SaveLogs: false,
					SpinType: 'spin',
					Notes: null
				},
				saving: false
			}
		},
		async mounted() {
			let tmp = await api.Projects.getProjectConfig(this.$route.params.cuuid)
			this.config = tmp.Configuration
			this.SpinTypes.push({
					"name": "Spin",
					"id": "spin",
	  	})
			if(typeof tmp.Configuration.buyFeatures !== 'undefined'){
				if(tmp.Configuration.buyFeatures.enabled){
					tmp.Configuration.buyFeatures.tiers.forEach(function(v,i){
						this.SpinTypes.push({
							"name": "Buy feature #"+v.id,
							"id": "buy_feature:"+v.id
						});
					}.bind(this))
				}
			}
			this.model.Paylines = this.config.valid_paylines
			this.model.Version = this.config.version
		},
		methods: {
			async save() {
				this.saving = true
				let report = await api.Reports.create(this.model).catch(() => this.saving = false)
				this.$router.push({
					name: 'projects-editor-uuid-cuuid-tests-reports-report_id',
					params: {
						report_id: report.Id
					}
				})
				this.saving = false
			}
		},
		computed: {
			formType() {
				return `${this.model.Type}-report-form`
			},
			totalSpins() {
				if (this.model.Type === 'standard') {
					return this.model.Spins.reduce((c, s) => c + parseInt(s), 0) * this.model.Paylines.length;
				} else {
					return this.model.Spins.length * this.model.Spins[0] * this.model.Paylines.length;
				}
			},
		}
	}
</script>
