<template>
	<div class="control number d-inline-flex justify-content-center">
		<template v-if="disabled">
			<div v-text="current"></div>
		</template>
		<div class="d-flex align-items-center flex-grow-1" v-else>
			<input
					type="number"
					:min="min"
					:max="max"
					v-model.number="current"
					@keydown.esc="current = value"
					@keydown.up.prevent="increment"
					@keydown.down.prevent="decrement"
					ref="input"
					:step="step"
					:class="{'min-value': isMin}"
					class="bg-light"
					@focus="$event.target.select()"
					@click="$event.target.select()"
			/>
			<slot></slot>
			<div class="controller" v-if="!disabled">
				<button class="decrement-button" tabindex="-1" :disabled="decrementDisabled" @click.prevent="decrement">
					−
				</button>
				<button class="increment-button" tabindex="-1" :disabled="incrementDisabled" @click.prevent="increment">
					+
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		disabled: Boolean,
		max: {
			type: Number,
			default: Infinity
		},
		min: {
			type: Number,
			default: -Infinity
		},
		value: {
			required: true
		},
		step: {
			type: Number,
			default: 1
		}
	},

	data() {
		return {
			decrementDisabled: false,
			incrementDisabled: false
		}
	},

	methods: {
		increment() {
			if (this.disabled || this.incrementDisabled) {
				return
			}

			this.current += this.step
			this.decrementDisabled = false
		},
		decrement() {
			if (this.disabled || this.decrementDisabled) {
				return
			}

			this.current -= this.step
			this.incrementDisabled = false
		}
	},

	computed: {
		isMin() {
			return this.value <= 0
		},
		current: {
			get() {
				return this.value
			},
			set(value) {

				if (this.current === value || typeof this.value !== 'number') {
					return
				}
				if (value <= this.min) {
					value = this.min
					this.decrementDisabled = true
				}
				if (value >= this.max) {
					value = this.max
					this.incrementDisabled = true
				}

				this.$emit("input", value)
			}
		}
	},

	mounted() {
		if (this.isMin) {
			this.decrementDisabled = true
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/assets/style/_palette";

$link-color: $color-primary;
$gray-light: $color-grey;
$gray-medium: $color-dark-grey;
$gray-dark: $color-dark-grey;
$bg-color: white;

.control.number {

	input {
		background-color: transparent;
		border: 0;
		border-radius: 3px;
		font-size: .875rem;
		line-height: 1.65;
		margin: 0 auto;
		text-align: right;
		width: 100%;
		min-width: 30px;
		//max-width: 60px;
		vertical-align: top;
		-moz-appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:focus {
			outline: 0;
			box-shadow: 0 0 0 0.2rem $link-color;
		}

		&.min-value {
			color: transparentize(black, 0.9);
			background-color: transparent !important;
		}
	}

	.controller {
		display: inline-flex;

		button {
			background: transparent;
			border: 0;
			color: $gray-dark;
			cursor: pointer;
			text-align: center;
			user-select: none;
			padding: 1px 6px;
			margin-left: 3px;
			font-size: 1.3em;

			&:hover,
			&:active,
			&:focus {
				border-color: $link-color;
				color: $link-color;
				outline: none;
			}


			&:disabled,
			&.is-disabled {
				color: $color-grey;
				opacity: 1;
			}
		}
	}

}

p {
	font-family: Impact, sans-serif;
	font-size: 2em;
	margin-bottom: .5em;
	text-align: center;
}
</style>
