<template>
	<div class="row">
		<div class="col">
			<div class="form-group">
				<h5>Lines</h5>

				<valid-paylines-list v-model="model.Paylines" :valid-paylines="validPaylines"></valid-paylines-list>

				<button class="btn btn-link px-0 text-small mx-1"
						@click.prevent="model.Paylines = validPaylines"><i
						class="fa fa-plus"></i>
					All
				</button>
				<button class="btn btn-link px-0 text-small mx-1"
						@click.prevent="model.Paylines = []"><i class="fa fa-redo-alt"></i>
					Reset
				</button>
			</div>
		</div>
		<div class="col">
			<div class="form-group">
				<h5>Spins</h5>

				<div class="form-check" v-for="spins in standard_spins" :key="spins">
					<input class="form-check-input mr-2" :id="`s-${spins}`" type="checkbox"
						   v-model="model.Spins" :value="spins">
					<label :for="`s-${spins}`" class="form-check-label">
						<span class="text-uppercase">{{spins | numeral('Oa')}}</span>
						<span class="ml-2 text-light text-small">spins</span>
					</label>
				</div>

				<button class="btn btn-link px-0 text-small mx-1"
						@click.prevent="model.Spins = standard_spins"><i
						class="fa fa-plus"></i> All
				</button>
				<button class="btn btn-link px-0 text-small mx-1"
						@click.prevent="model.Spins = []"><i class="fa fa-redo-alt"></i>
					Reset
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import ValidPaylinesList from "../../valid-paylines-list";
	export default {
		components: {ValidPaylinesList},
		props: {
			value: {
				type: Object,
				default() {
					return {}
				}
			},
			validPaylines: {
				type: Array,
				default: () => []
			}
		},
		mounted() {
			this.model.Spins = this.standard_spins.filter(ss => ss <= 1000000)
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			},
			standard_spins() {
				return [
					100, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000, 10000000, 15000000, 20000000, 30000000, 50000000, 100000000, 500000000, 1000000000
				]
			},
		}
	}
</script>
