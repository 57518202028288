<template>
	<div>
		<div class="form-check" v-for="payline in validPaylines" :key="payline">
			<input class="form-check-input mr-2" :id="`p-${payline}`" type="checkbox"
				   v-model="model" :value="payline">
			<label :for="`p-${payline}`" class="form-check-label">
				<span class="text-uppercase">{{payline | numeral('Oa')}}</span>
				<span class="ml-2 text-light text-small">paylines</span>
			</label>
		</div>
	</div>
</template>

<script>
	export default {
		name: "valid-paylines-list",
		props: {
			value: {
				type: Array,
				default: () => []
			},
			validPaylines: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit("input", val)
				}
			}
		}
	}
</script>

<style scoped>

</style>
