<template>
	<div class="row">
		<div class="col">
			<div class="form-group">
				<h5>Lines</h5>

				<valid-paylines-list v-model="model.Paylines" :valid-paylines="validPaylines"></valid-paylines-list>

				<button class="btn btn-link px-0 text-small mx-1"
						@click.prevent="model.Paylines = validPaylines"><i
						class="fa fa-plus"></i>
					All
				</button>
				<button class="btn btn-link px-0 text-small mx-1"
						@click.prevent="model.Paylines = []"><i class="fa fa-redo-alt"></i>
					Reset
				</button>
			</div>
		</div>
		<div class="col">
			<div class="form-group">
				<h5>Number of tests</h5>
				<multiplier v-model="testNumber" :step="10"></multiplier>
			</div>
		</div>
		<div class="col">
			<div class="form-group">
				<h5>Single test spins</h5>
				<multiplier v-model="singleTestSpins"></multiplier>
			</div>
		</div>
	</div>
</template>

<script>
	import Multiplier from "../../slot-config-editor/prize/multiplier";
	import ValidPaylinesList from "../../valid-paylines-list";

	export default {
		components: {ValidPaylinesList, Multiplier},
		props: {
			value: {
				type: Object,
				default() {
					return {}
				}
			},
			validPaylines: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				singleTestSpins: 1000,
				testNumber: 1000
			}
		},
		mounted() {
			this.model.Spins = this.spins
		},
		watch: {
			spins() {
				this.model.Spins = this.spins
			},
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', {
						...val,
						Spins: this.spins
					})
				}
			},
			spins() {
				let spins = []

				for (let i = 0; i < this.testNumber; i++) {
					spins.push(this.singleTestSpins)
				}

				return spins
			},
			standard_spins() {
				return [
					10000, 50000, 100000, 500000, 1000000, 10000000, 15000000, 20000000, 30000000
				]
			},
		}
	}
</script>
